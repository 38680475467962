.track {
  overflow: hidden;
  position: relative;
  border: 1px rgba(0, 0, 0, 0) solid;
  margin: auto;
}
.track.open {
  /* box-shadow: 0 0 5em var(--main-color) inset; */
  /* border-color: var(--main-color-light); */
}
.track .title {
  margin: 0;
  padding: 1em;
  text-align: left;
  display: grid;
  grid-template-columns: 1.5em 2fr 1fr;
  gap: 0.5em;
}
.track.open .title {
  border-bottom: none;
}
.track .title h2 {
  min-width: 1em;
}
.track .title h3 {
  margin: 0;
}
.track .title p.subtitle {
  flex-basis: 100%;
  flex-shrink: 0;
  margin: 1em 0 0 0;
  /* text-align: right; */
}
.track .title a {
  color: var(--main-color-light);
  text-decoration: underline;
}
.track .title a:hover {
  font-weight: bold;
}
.track .backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  opacity: 0.2;
  /* filter: blur(5px) opacity(20%) grayscale(100%); */
}
.track.open .backdrop {
  /* filter: blur(5px) opacity(20%); */
  opacity: 0.2;
}
.track .content-outer {
  max-height: 0;
  transition: max-height 200ms;
}
.track.open .content-outer {
  max-height: 200vh;
}
.track .title h2 {
  margin: 0;
}
.track .title p {
  margin-bottom: 0;
}
.track .info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.5em;
}
.track .info > * {
  flex: 1 1 fit-content;
}
.author-type {
  font-size: 10.5px;
}
.soundcloud-player-outer {
  grid-column: 1/1;
  padding: 0;
}
.soundcloud-player {
  border: 1px solid transparent;
  overflow: hidden;
  position: sticky;
  bottom: 0;
  z-index: 9;
  box-shadow: 0 0 15px var(--main-color);
}
.soundcloud-player-outer.left .soundcloud-player {
  top: 1em;
  left: 1em;
  width: 260px !important;
  height: 260px !important;
}
.soundcloud-player.middle {
  display: none;
}
@media only screen and (max-width: 900px) {
  .track .title {
    grid-template-columns: 1em 1fr;
  }
  .track .title .info {
    grid-column: 2;
  }
}

@media only screen and (max-width: 1160px) {
  .soundcloud-player.middle {
    display: block;
  }
  .soundcloud-player-outer.left {
    display: none !important;
  }
}
@media only screen and (max-width: 450px) {
  .soundcloud-player.middle {
    margin-left: -0.5em;
    margin-right: -0.5em;
    width: calc(100% + 1em) !important;
  }
}

@keyframes opacity-easeInBounce {
  0% {
    opacity: 1;
  }

  4% {
    opacity: 0.98;
  }

  8% {
    opacity: 0.99;
  }

  18% {
    opacity: 0.94;
  }

  26% {
    opacity: 0.98;
  }

  46% {
    opacity: 0.75;
  }

  64% {
    opacity: 0.98;
  }

  76% {
    opacity: 0.44;
  }

  88% {
    opacity: 0.11;
  }

  100% {
    opacity: 0;
  }
}
