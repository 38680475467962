.lang-btn {
  display: inline-block;
  margin: 0 0.5em;
}
.lang-btn:first-child {
  margin-left: 0;
}
.menu h3 svg {
  vertical-align: bottom;
}
.menu .release-party a{
    animation: color-rotate 1.2s infinite ease-in-out alternate;
}
.menu .release-party a:hover{
    animation: none;
}

@keyframes color-rotate {
    from {
	opacity: 0.6;
    }
    to {
	opacity: 1.0;
    }
}
