.button{
    display: inline-block;
    padding: 0.2em 1em;
    border: 1px var(--main-color-light) solid;
    color: var(--main-color-light);
    cursor: pointer;
}
.button:hover, .button:focus{
    animation: opacity-easeInBounce 100ms 1;
    color: var(--main-color);
    background-color: var(--main-color-light);
}
.floatPanel.float{
    position: fixed;
    background-color: rgba(var(--main-color-value), 0.9);
    z-index: 100;
}
.floatPanel>*{
    display: block;
    margin-right: 0.5em !important;
}
