:root {
  --main-color: rgb(32, 32, 32);
  --main-color-light: rgb(219, 219, 219);
  --main-color-value: 32, 32, 32;
  --main-color-light-value: 219, 219, 219;
}
body {
  background-color: var(--main-color);
  color: var(--main-color-light);
  text-align: justify;
  text-justify: inter-character;
}
.page-outer {
  display: grid;
  grid-template-columns:
    [left-side] 1fr [middle] minmax(0px, 800px) [right-side] minmax(224px, 1fr)
    [end];
  grid-template-rows: [splash] auto [intro] auto [track] auto [thank] auto [footer] auto [end];
}
.left-padder,
.right-padder {
  /* width: 30vw; */
  flex-basis: 250px;
  flex-grow: 1;
  position: sticky;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
}
.left-padder {
  padding: 0;
  grid-column: left-side;
  grid-row-start: 1;
}
.right-padder {
  grid-column: right-side;
  grid-row-start: 1;
  z-index: 10;
}
.middle-content {
  position: relative;
}
.floating-background {
  grid-column: 1/4 !important;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 20vw, 100% 100%, 0 100%);
  margin-top: -10vw;
  margin-bottom: -10vw;
  z-index: -1;
}
.floating-background:first-child {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.section-outer.floating-background {
  padding: 0;
}
.floating-background img {
  object-fit: cover;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.section-outer {
  grid-column: middle;
  padding: 5vw 2em calc(2em + 10vw) 2em;
}
.section-outer:nth-child(1),
.section-outer:nth-child(2) {
  padding-top: 0;
}
.content-section {
  width: 100%;
}
body a,
body a:visited {
  color: var(--main-color-light);
  margin-bottom: 0;
  cursor: pointer;
  text-decoration: underline;
}
a:hover,
a:focus,
a:active {
  animation: opacity-easeInBounce 100ms 1;
  color: var(--main-color) !important;
  background-color: var(--main-color-light);
  margin-bottom: 0;
}
.menu {
  border-left: var(--main-color-light) 2px solid;
  margin: 0;
  padding-left: 1em;
  list-style-type: none;
}
.menu a,
.menu a:visited {
  color: var(--main-color-light);
  margin-bottom: 0;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.menu a:hover,
.menu a:focus,
.menu a:active {
  animation: opacity-easeInBounce 100ms 1;
  color: var(--main-color);
  background-color: var(--main-color-light);
  margin-bottom: 0;
}
h2,
.menu a.activated {
  font-weight: 400;
  text-shadow: -2px 0 1px #ff000060, 2px 0 1px #00ff0060, 4px 0 1px #0000ff60;
}
a.activated {
  animation: opacity-easeInBounce 100ms 1;
}
.extra {
}
.footer {
  /* padding-top: 2em; */
  min-height: 100%;
  /* display: flex; */
  flex-direction: row;
  justify-content: center;
  background-color: var(--main-color);
  z-index: 1;
  position: relative;
}
.footer img {
  margin-bottom: 0;
}

.splash-outer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100vh;
}
.hamburger-outer {
  display: none;
  grid-row: splash/footer;
  grid-column: middle;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 2;
  max-height: 100vh;
  height: max-content;
  overflow-y: auto;
}
.hamburger {
  background-color: var(--main-color);
}
.hamburger.collapse .menu-outer {
  height: 100%;
  padding-bottom: 1em;
}
.hamburger .menu {
  border-left: 0px;
}

.staff {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-gap: 0.5em;
  max-width: 640px;
}
.staff > p {
  margin: 0;
}
.staff > * > *,
.staff.special > * {
  border-left: 2px solid var(--main-color-light);
  padding-left: 0.5em;
  justify-self: stretch;
}
.staff > .with-job {
  grid-row: auto / span 2;
}
.staff::after {
  content: "";
  flex-basis: 0;
  flex-grow: 100;
  flex-shrink: 100;
}
.staff .job {
  text-shadow: -2px 0 1px #ff000060, 2px 0 1px #00ff0060, 4px 0 1px #0000ff60;
  font-size: x-small;
  font-weight: lighter;
}
.footer-content {
  padding: 0 1em;
}

.button-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: x-small;
  gap: 0.5em;
}
.button-list .button {
  padding: 0.2em 0.5em;
  display: flex;
  gap: 0.5em;
  align-items: center;
}
.button-list .button svg {
  font-size: small;
}
.timeline p {
  font-size: smaller;
  line-height: normal;
  font-style: italic;
}
.timeline p:first-child {
  font-size: medium;
  line-height: inherit;
  font-style: normal;
  font-weight: 700;
}
.timeline ul {
  border-left: 2px solid var(--main-color-light);
  margin-left: 1em;
}
.timeline li {
  margin-left: 0.83335em;
  list-style: outside none none;
}
.timeline li::before {
  content: "";
  float: left;
  margin-left: -2em;
  border-bottom: 2px var(--main-color-light) solid;
  width: 1em;
  height: 1em;
}
.xx-smaller {
  font-size: x-small;
}
.expending-button {
  display: none;
  cursor: pointer;
}
.expending-button:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 900px) {
  .splash-outer {
    text-align: center;
  }
  .splash-outer img {
    max-width: 50vw;
    margin: auto !important;
  }
  .left-padder,
  .right-padder {
    display: none;
  }
  .page-outer {
    grid-template-columns: [middle] auto [end];
    margin-top: 47.75px;
  }
  .section-outer {
    width: 100vw;
  }
  .splash-outer {
    flex-direction: column-reverse;
    min-height: auto;
    height: auto !important;
    width: 100% !important;
  }
  .splash-outer div {
    min-height: auto !important;
    margin-top: 0 !important;
    width: 100%;
    height: auto !important;
    position: relative !important;
  }
  .hamburger .topbar h2 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0.5em;
    cursor: pointer;
  }
  .hamburger-outer {
    display: block;
  }
  .staff {
    width: calc(100vw - 4em);
  }
  .expending-content.collapsed {
    max-height: 100vh;
    overflow: hidden;
    mask-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .expending-button {
    display: block;
  }
}
@media only screen and (max-width: 450px) {
  .section-outer {
    padding: 5vw 0.5em calc(0.5em + 10vw) 0.5em;
  }
  .splash-outer img {
    max-width: 100%;
  }
  .footer > div {
    flex-direction: column-reverse !important;
  }
  .footer > div > div,
  .footer > div > div > img {
    width: 100%;
  }
  .footer-content {
    padding: 1em 0;
  }
  .rukaimg{
    display: none;
  }
}

@keyframes contrast-blending {
  from {
    filter: hue-rotate(-10deg) contrast(0.6);
  }
  to {
    filter: hue-rotate(10deg) contrast(0.8);
  }
}
@keyframes opacity-easeOutElastic {
  0% {
    opacity: 1;
  }

  16% {
    opacity: -0.32;
  }

  28% {
    opacity: 0.13;
  }

  44% {
    opacity: -0.05;
  }

  59% {
    opacity: 0.02;
  }

  73% {
    opacity: -0.01;
  }

  88% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
@keyframes opacity-easeInBounce {
  0% {
    opacity: 1;
  }

  4% {
    opacity: 0.98;
  }

  8% {
    opacity: 0.99;
  }

  18% {
    opacity: 0.94;
  }

  26% {
    opacity: 0.98;
  }

  46% {
    opacity: 0.75;
  }

  64% {
    opacity: 0.98;
  }

  76% {
    opacity: 0.44;
  }

  88% {
    opacity: 0.11;
  }

  100% {
    opacity: 0;
  }
}
